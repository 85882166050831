<template>
  <section class="course-groups">
    <div class="course-groups__cards ">
      <div v-for="(item, i) in list" :key="i">
        <nuxt-link :to="link(item)" class="course-groups__card">
          <!-- left side - language and icon -->
          <div class="course-groups__card-left">
            <div class="course-groups__icon" :class="bgColor(item.design_color)">
              <BaseIcon
                name="import-contacts"
                type="custom"
                color="fill-body-1"
                custom-size="w-5 h-5 lg:w-8 lg:h-8"
                view-box="0 0 32 32"
              />
            </div>
            <span class="course-groups__card-left-name">{{ item.name }}</span>
          </div>
          <!-- right side  - tutor avatar and numbers-->
          <div class="course-groups__card-right">
            <div class="course-groups__card-right-pictures">
              <BaseAvatarTeacher
                v-for="(img,key) in item.teachers_picture"
                :key="key"
                :alt="img.alt || 'tutor avatar'"
                :img-url="img"
                :size="{lg: '38', xs: '38'}"
                class="-mr-4 border-2 border-border-cart rounded-full"
              />
            </div>
            <span class="lg-flex-hidden items-center">
              <BaseIcon
                name="keyboard_arrow_right"
                color="color-icon"
                size="lg"
              />
            </span>

            <span
              v-if="getShowTeachersCount"
              class="course-groups__card-right-numbers"
              :style="{'color': item.color}"
            >
              <span>{{ item.teachers_count }}</span>
              <span>{{ $t('labels.tutors') }}</span>
            </span>
          </div>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HomeCourseGroups',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('local', ['getShowTeachersCount']),
    designColors () {
      return {
        primary: {
          bg_disable: 'bg-primary-100'
        },
        green: {
          bg_disable: 'bg-green-100'
        },
        purple: {
          bg_disable: 'bg-purple-150'
        },
        orange: {
          bg_disable: 'bg-orange-150'
        },
        pink: {
          bg_disable: 'bg-pink-150'
        },
        teal: {
          bg_disable: 'bg-teal-200'
        }
      }
    }
  },
  methods: {
    link (item) {
      return this.$links.tutor_list_params(this.$nuxt, item)
    },
    bgColor (color) {
      return color ? this.designColors[color.toLowerCase()].bg_disable : 'bg-primary-150'
    }
  }
}
</script>

<style lang="scss" scoped>
.course-groups {
  @apply container ;
  &__title {
    @apply mb-8;
  }
  &__cards {
    @apply grid grid-cols-1 lg:grid-cols-2 lg:gap-4 gap-4;
  }
  &__card {
    @apply flex justify-between rounded-lg
     p-2 lg:p-4 cursor-pointer border border-primary-100;
    &-left {
      @apply flex items-center;
      &-name {
        @apply ml-3 text__body-3-md--bold lg:text__body-1-md--bold text-body-1;
      }
    }
    &-right {
      @apply flex space-x-6 items-center justify-between;
      &-numbers {
        @apply text__caption-1-xs--regular lg:text__body-2-md--semibold;
      }
      &-pictures {
        @apply flex flex-row items-center mr-4 lg:mr-0;
        img {
          @apply rounded-full w-10 h-10 lg:w-12 lg:h-12 mr-[-16px] border border-primary-100;
        }
        img:last-of-type {
          @apply mr-0;
        }
      }
    }
  }
  &__icon {
    @apply w-8 h-8 lg:w-12 lg:h-12 flex justify-center items-center rounded-lg;
  }
}
</style>
