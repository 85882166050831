<script>
import { shortenText } from '@helper/utils'
import detectDeviceMixin from '@helper/detectDeviceMixin'
export default {
  name: 'ArticlesSliderCard',
  mixins: [detectDeviceMixin],
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    title () {
      return shortenText(this.item.title, this.isMobile ? 60 : 70, true)
    },
    content () {
      return shortenText(this.item.content, this.isMobile ? 100 : 120, true)
    }
  }
}
</script>

<template>
  <div class="articles-item">
    <div
      class="articles-item__img"
      :style="[{
        backgroundImage: `url(${item.image})`
      }]"
    />
    <p class="articles-item__title">
      {{ title }}
    </p>
    <p class="articles-item__text" v-html="content" />
    <p class="articles-item__date">
      {{ item.published_at }}
    </p>
    <div class="flex">
      <BaseButton
        target="_blank"
        tag="a"
        :href="item.link"
        :label="$t('common.actions.read_more')+ '...'"
        flat
        light
        size="sm"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.articles-item {
  @apply border border-primary-100 rounded-2xl p-4 w-full;
  &__title {
    @apply text__body-2-xs--bold lg:text__body-1-md--bold text-title mt-4;
  }
  &__text {
    @apply text__caption-xs--regular lg:text__body-3-md--regular text-body-2 mt-2;
  }
  &__date {
    @apply text__caption-1-xs--regular lg:text__caption-xs--regular text-label-text mt-2;
  }
  &__img {
    @apply w-full rounded-2xl h-[190px] bg-cover bg-no-repeat;
  }
}
</style>
