<script>
import BaseTitleSection from '@design-system/base/title/title-section.vue'
import { mapGetters } from 'vuex'
import BaseSlider from '@app/design-system/base/slider/slider.vue'
import ArticlesSliderCard from './articles-slider-card.vue'

export default {
  name: 'SectionArticlesSlider',
  components: { ArticlesSliderCard, BaseTitleSection, BaseSlider },
  data () {
    return {
      title: 'Related Articles',
      sliderOption: {
        spaceBetween: 16,
        slidesPerView: 3,
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 24
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1.34,
            spaceBetween: 16
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('global', ['getBlogPosts']),
    posts () {
      return this.getBlogPosts?.posts
    },
    showMore () {
      return this.getBlogPosts?.show_more
    }
  }
}
</script>

<template>
  <div v-if="posts && posts.length > 0" class="container">
    <div class="flex justify-between items-center">
      <BaseTitleSection :title="title" class="text-left" />
      <BaseButton
        :label="$t('common.actions.show_more')"
        tag="a"
        :to="showMore"
        flat
        light
        size="sm-md"
      />
    </div>
    <div class="related-articles">
      <BaseSlider
        :items="posts"
        :options="sliderOption"
        pagination="SliderPaginationArrow"
      >
        <template #item="{ data }">
          <ArticlesSliderCard :item="data" />
        </template>
      </BaseSlider>
    </div>
  </div>
</template>

<style scoped lang="scss">
.related-articles {
  @apply container relative w-full overflow-x-auto mt-6;
  &::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  &__wrapper {
    @apply w-[1000px] lg:w-auto flex flex-row space-x-3 flex-wrap lg:flex-nowrap;
  }
}
</style>
