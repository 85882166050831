<template>
  <div class="list-filter-text">
    <h1 class="list-filter-text__heading">
      <span>Find the best</span>
      <span>online tutors at</span>
      <span>Ostado!</span>
    </h1>
    <h2 class="list-filter-text__subheading">
      {{ subheading }}
    </h2>
  </div>
</template>
<script>

export default {
  name: 'TopBannerText',
  computed: {
    subheading () {
      return 'The best local or online tutors are here!'
    }
  }
}
</script>

<style scoped lang="scss">
.list-filter-text {
  @apply relative w-full lg:w-[470px] text-center lg:text-left;
  &__heading{
    @apply flex flex-col text-title;
    font-family: 'Open Sans', 'sans-serif';
    > span {
      @apply font-semibold text-[26px] lg:text-[40px] leading-[32px] lg:leading-[44px];
    }
    > span:first-of-type {
      @apply font-light text-[33px] lg:text-[51px] leading-[36px] lg:leading-[50px];
    }
    > span:last-of-type {
      @apply font-extrabold text-[49px] lg:text-[75px] leading-[48px] lg:leading-[70px];
    }
  }
  &__subheading{
    @apply text__headline-5-xs--regular lg:text__body-2-md--regular mt-6 lg:mt-8 text-title;
  }
}
</style>
