<script>
import CommonFindBestTutorsBtn from '@/components/common/find-best-tutors/find-best-tutors-btn.vue'

export default {
  name: 'HomeAboutUs',
  components: { CommonFindBestTutorsBtn },
  computed: {
    title () {
      return 'About Ostado...'
    },
    list () {
      return [
        {
          title: 'Local expertise, global expansion:',
          body: 'With 8+ years of local success, Ostado is now going global with WorldStartups\' support.'
        },
        {
          title: 'Quality assured, support guaranteed:',
          body: 'Count on us for top-notch service and unwavering support, ensuring a smooth learning journey.'
        },
        {
          title: 'Revolutionizing tutoring worldwide:',
          body: 'Our aim is simple: reshape private tutoring globally, powered by our expertise and partnership.'
        },
        {
          title: 'Your satisfaction, our mission:',
          body: 'At Ostado, your satisfaction drives us. Experience hassle-free learning and teaching with us.'
        }
      ]
    }
  }
}
</script>

<template>
  <div class="home-about-us">
    <div class="w-full lg:w-4/12 mt-8 lg:mt-0">
      <BaseTitleSection :title="title" class="home-about-us__heading lg-block-hidden" />
      <div class="space-y-4 mb-8">
        <div v-for="(item,key) in list" :key="key" class="home-about-us__item">
          <p class="home-about-us__title">
            {{ item.title }}
          </p>
          <p class="home-about-us__body">
            {{ item.body }}
          </p>
        </div>
      </div>
      <CommonFindBestTutorsBtn name="about-us" classes="lg:w-[276px] w-full max-w-full" />
    </div>
    <div class="w-full lg:w-6/12 lg:rtl">
      <BaseTitleSection :title="title" class="home-about-us__heading lg-hidden-block" />
      <BaseImg
        class="w-full"
        :src="require('@images/public/home/about-us/about_us.webp')"
        :sizes="{lg:['584','480'], xs: ['328','263']}"
        lazy
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.home-about-us {
  @apply container flex lg:justify-between flex-col-reverse lg:flex-row;
  &__heading {
    @apply text-center lg:text-left mb-6 lg:mb-8;
  }
  &__item {
  }
  &__title {
    @apply lg:text__body-2-md--bold text__headline-5-xs--bold text-body-1;
  }
  &__body {
    @apply lg:text__body-2-md--regular text__body-2-xs--regular text-body-2 mt-2;
  }
}
</style>
