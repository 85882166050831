<template>
  <main class="page-home">
    <section>
      <HomeTopBanner class="mt-14 lg:mt-[80px]" />
      <SectionStatistics v-if="hasStatistics" class="mt-14 lg:mt-[80px]" />
      <HomeCourseGroups v-if="hasCourseGroup" class="mt-14 lg:mt-[80px]" :list="getCourseGroups || []" />
    </section>
    <HomeTopTutors v-if="hasBestTeacher" />
    <HomeHowItWorks />
    <HomeAboutUs />
    <HomeBecomeTutor />
    <section class="container">
      <SectionFAQs v-if="hasFAQS" class="w-full lg:w-10/12 mx-auto" />
    </section>
    <SectionArticlesSlider />
    <HomeWorldMap :cities="getImportantCities || []" />
    <section>
      <SectionSeoContent
        v-if="hasSeoContent"
        class="mb-3"
        heading-class="lg:text__headline-3-md--bold text__headline-3-xs--bold"
      />
      <SectionSeoContentInternalLinks v-if="hasInternalLinks" class="mt-4" />
    </section>
    <CommonFindBestTutorsAction />
    <HomeStartTutoringBanner class="page-home__tutoring-banner" />
  </main>
</template>

<script>
import { hydrateWhenVisible, hydrateWhenIdle } from 'vue-lazy-hydration'
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'
import { renderHead } from '@helper/head/head-home'
import SectionSeoContent from '@design-system/section/seo-content/seo-content'
import SectionSeoContentInternalLinks from '@design-system/section/seo-content/seo-content-internal-links'
import SectionArticlesSlider from '@design-system/section/articles/articles-slider.vue'
import HomeTopBanner from '~/components/pages/home/top-banner/top-banner.vue'
import HomeCourseGroups from '~/components/pages/home/course-groups/course-groups.vue'
import HomeAboutUs from '@/components/pages/home/about-us/about-us.vue'
import CommonFindBestTutorsAction from '@/components/common/find-best-tutors/find-best-tutors-action.vue'
import HomeStartTutoringBanner from '@/components/pages/home/start-tutoring-banner/start-tutoring-banner.vue'
export default {
  name: 'PageHome',
  components: {
    HomeStartTutoringBanner,
    CommonFindBestTutorsAction,
    HomeAboutUs,
    HomeTopBanner,
    HomeCourseGroups,
    SectionArticlesSlider,
    SectionStatistics: hydrateWhenIdle(() => import('~/components/pages/home/statistics/statistics'),
      { observerOptions: { rootMargin: '100px' } }),
    HomeTopTutors: hydrateWhenVisible(
      () => import('~/components/pages/home/top-tutors/top-tutors.vue'),
      { observerOptions: { rootMargin: '100px' } }
    ),
    HomeHowItWorks: hydrateWhenIdle(() => import('~/components/pages/home/how-it-works/how-it-works.vue'),
      { observerOptions: { rootMargin: '100px' } }),
    HomeBecomeTutor: hydrateWhenIdle(() => import('~/components/pages/home/become-tutor/become-tutor.vue'),
      { observerOptions: { rootMargin: '100px' } }),
    SectionFAQs: hydrateWhenIdle(() => import('@design-system/section/faqs/faqs'),
      { observerOptions: { rootMargin: '100px' } }),
    HomeWorldMap: hydrateWhenIdle(() => import('~/components/pages/home.old/world-map/world-map.vue'),
      { observerOptions: { rootMargin: '100px' } }),
    SectionSeoContent,
    SectionSeoContentInternalLinks
  },
  auth: false,
  layout: 'main',
  async asyncData ({ params, store }) {
    await store.dispatch('local/asyncDataHome')
  },
  head () {
    return renderHead({ meta: this.getMeta })
  },
  computed: {
    ...mapGetters('global', ['getMeta', 'getTestimonials', 'getStatistics',
      'getBestTeachers', 'getFaqs', 'getSeoContent', 'getInternalLinks']),
    ...mapGetters('local', ['getCourseGroups',
      'getImportantCities']),
    hasFeedback () {
      return !isEmpty(this.getTestimonials)
    },
    hasCourseGroup () {
      return !isEmpty(this.getCourseGroups)
    },
    hasStatistics () {
      return !isEmpty(this.getStatistics)
    },
    hasBestTeacher () {
      return !isEmpty(this.getBestTeachers)
    },
    hasFAQS () {
      return !isEmpty(this.getFaqs)
    },
    hasSeoContent () {
      return !isEmpty(this.getSeoContent)
    },
    hasInternalLinks () {
      return !isEmpty(this.getInternalLinks)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-home {
  @apply lg:space-y-[160px] space-y-20 max-w-full overflow-hidden;
  &__tutoring-banner {
  }
}
</style>
