<template>
  <div class="pagination-arrow lg-flex-hidden">
    <div
      class="pagination-arrow__nav left"
      :class=" {'disabled' :prevDisabled}"
      @click="slidePrev"
    >
      <BaseIcon name="chevron_left" size="lg" />
    </div>
    <div class="pagination-arrow__bullets ">
      <div
        class="pagination-arrow__bullets--item"
        :class="[{'pagination-arrow__bullets--active':firstActive}]"
        @click="slidePrev"
      />
      <div
        v-if="showCenter"
        class="pagination-arrow__bullets--item"
        :class="{'pagination-arrow__bullets--active':centerActive}"
        @click="slideCenter"
      />
      <div
        class="pagination-arrow__bullets--item"
        :class="[{'pagination-arrow__bullets--active':lastActive}]"
        @click="slideNext"
      />
    </div>
    <div
      class="pagination-arrow__nav right"
      :class="{'disabled' :nextDisabled}"
      @click="slideNext"
    >
      <BaseIcon name="chevron_right" size="lg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderPaginationArrow',
  props: {
    showCenter: {
      type: Boolean,
      default: false
    },
    firstActive: {
      type: Boolean,
      default: false
    },
    centerActive: {
      type: Boolean,
      default: false
    },
    lastActive: {
      type: Boolean,
      default: false
    },
    prevDisabled: {
      type: Boolean,
      default: false
    },
    nextDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    slideNext () {
      this.$emit('slideNext')
    },
    slideCenter () {
      this.$emit('slideCenter')
    },
    slidePrev () {
      this.$emit('slidePrev')
    }
  }
}
</script>

<style lang="scss" >
.pagination-arrow {
  @apply items-center mt-8 justify-center;
  &__bullets {
    @apply  justify-center mx-4;
    &--item {
      @apply mx-1 bg-primary-100 inline-block rounded-full cursor-pointer
      w-3 h-3;
    }
    &--active {
      @apply  border-primary bg-primary;
    }
  }
  &__nav {
    @apply justify-center items-center cursor-pointer;
    &.disabled {
      @apply opacity-50 cursor-default;
    }
  }
}
</style>
