<script>
import { mapGetters } from 'vuex'
import CommonFindBestTutorsBtn from '@/components/common/find-best-tutors/find-best-tutors-btn.vue'

export default {
  name: 'HomeStartTutoringBanner',
  components: { CommonFindBestTutorsBtn },
  computed: {
    ...mapGetters('local', ['getJoinUs'])
  }
}
</script>

<template>
  <section class="tutoring-banner">
    <div class="text-center text-white">
      <p class="text__headline-3-xs--bold lg:text__headline-3-md--bold mb-3 lg:mb-4">
        {{ getJoinUs.headline }}
      </p>
      <p class="text__caption-xs--regular lg:text__body-2-md--regular">
        {{ getJoinUs.caption }}
      </p>
      <div class="mx-auto mt-4 lg:mt-8 lg:w-[250px] w-[200px]">
        <BaseButton
          icon-left-custom="teacher"
          classes="lg:w-[245px] w-full"
          size="md-lg"
          tag="a"
          :label="$t('actions.become_tutor')"
          :to="$links.absolute($nuxt, 'start_teaching')"
        />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.tutoring-banner {
  @apply w-full h-[220px] lg:h-[560px] bg-cover bg-no-repeat flex items-center justify-center px-4;
  background-image: url("@images/public/home/banner/home-tutoring-banner.jpg");
}
</style>
